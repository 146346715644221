import { getCompleteStyle, complete, getStyle, mergeStyle } from '../helpers';
export default function fontSize(value, config) {
    const configValue = config === null || config === void 0 ? void 0 : config[value];
    if (!configValue) {
        return null;
    }
    if (typeof configValue === `string`) {
        return getCompleteStyle(`fontSize`, configValue);
    }
    let style = {};
    const [fontSize, rest] = configValue;
    const fontSizeStyle = getStyle(`fontSize`, fontSize);
    if (fontSizeStyle) {
        style = fontSizeStyle;
    }
    if (typeof rest === `string`) {
        return complete(mergeStyle(`lineHeight`, rest, style));
    }
    const { lineHeight, letterSpacing } = rest;
    if (lineHeight) {
        mergeStyle(`lineHeight`, lineHeight, style);
    }
    if (letterSpacing) {
        mergeStyle(`letterSpacing`, letterSpacing, style);
    }
    return complete(style);
}
