import { parseNumericValue, toStyleVal } from '../helpers';
export default function spacing(type, direction, isNegative, value, config) {
    let numericValue = ``;
    if (value[0] === `[`) {
        numericValue = value.slice(1, -1);
    }
    else {
        const configValue = config === null || config === void 0 ? void 0 : config[value];
        if (!configValue) {
            return null;
        }
        numericValue = configValue;
    }
    const parsed = parseNumericValue(numericValue);
    if (!parsed) {
        return null;
    }
    let [number, unit] = parsed;
    if (isNegative) {
        number = -number;
    }
    return spacingStyle(number, unit, direction, type);
}
function spacingStyle(number, unit, direction, type) {
    const pixels = toStyleVal(number, unit);
    if (pixels === null) {
        return null;
    }
    switch (direction) {
        case `All`:
            return {
                kind: `complete`,
                style: {
                    [`${type}Top`]: pixels,
                    [`${type}Right`]: pixels,
                    [`${type}Bottom`]: pixels,
                    [`${type}Left`]: pixels,
                },
            };
        case `Bottom`:
        case `Top`:
        case `Left`:
        case `Right`:
            return {
                kind: `complete`,
                style: {
                    [`${type}${direction}`]: pixels,
                },
            };
        case `Vertical`:
            return {
                kind: `complete`,
                style: {
                    [`${type}Top`]: pixels,
                    [`${type}Bottom`]: pixels,
                },
            };
        case `Horizontal`:
            return {
                kind: `complete`,
                style: {
                    [`${type}Left`]: pixels,
                    [`${type}Right`]: pixels,
                },
            };
        default:
            return null;
    }
}
