/** Auxilary function to shorten code */
export function objFromKeys(keys, fun) {
    return keys.reduce((obj, key) => (Object.assign(Object.assign({}, obj), { [key]: fun(key) })), {});
}
export function cornerToStyle(corner, alternative) {
    switch (corner) {
        case 'topLeft': return alternative ? 'borderTopStartRadius' : 'borderTopLeftRadius';
        case 'topRight': return alternative ? 'borderTopEndRadius' : 'borderTopRightRadius';
        case 'bottomLeft': return alternative ? 'borderBottomStartRadius' : 'borderBottomLeftRadius';
        case 'bottomRight': return alternative ? 'borderBottomEndRadius' : 'borderBottomRightRadius';
    }
}
